
//iPhone 5
@media (max-width: 325px) {
  label {
    &.grey-18,
    &.grey-20,
    &.grey-light-20,
    &.grey-24,
    &.red-20,
    &.red-20-bold,
    &.red-24,
    &.red-24-bold,
    &.blue-20 {
      font-size: 18px;
    }
  }

  h3 {
    margin-bottom: 5px;
    font-size: 20px;
  }
  .content {
    width: 300px;
    font-size: 18px;
  }
}
