@import "./variables.scss";

@font-face {
  font-family: 'Effra';
  src:
    url('/assets/fonts/effra/Effra-Regular.eot');
  src:
    url('/assets/fonts/effra/Effra-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/effra/Effra-Regular.woff') format('woff'),
    url('/assets/fonts/effra/Effra-Regular.ttf') format('truetype'),
    url('/assets/fonts/effra/Effra-Regular.svg#Effra-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Effra Medium';

  src:
    url('/assets/fonts/effra/Effra_Std_Md.woff') format('woff'),
    url('/assets/fonts/effra/Effra_Std_Md.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


h3 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  color: $primary_red_color;
  letter-spacing: 0.9px;
  font-family: "Effra Medium", sans-serif;
}

h4 {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.54px;
  color: $primary_black_color;
  opacity: 1;
}

hr {
  margin-left: 0;
  margin-right: 0;
}

label {
  margin-bottom: 0px;
  font: Regular 14px/20px Effra;
  letter-spacing: 0.48px;
  color: $primary_black_color; //do not change
  opacity: 1;
  line-height: 1.2;
  letter-spacing: 0.54px;

  &.grey-18 {
    color: $primary_grey_color;
    //color: $secondary_grey_color;
    font-size: 18px;
  }

  &.grey-20 {
    font-size: 20px;
    letter-spacing: 0.54px;
    color: $primary_grey_color;
    line-height: 26px;
  }

  &.grey-light-20 {
    font-size: 20px;
    letter-spacing: 0.54px;
    color: $secondary_grey_color;
  }

  &.grey-24 {
    color: $primary_grey_color;
    font-size: 24px;
    font-weight: bold;
  }

  &.red-20 {
    color: $primary_red_color;
    font-size: 20px;
  }

  &.red-20-bold {
    color: $primary_red_color;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }

  &.red-24 {
    color: $primary_red_color;
    font-size: 24px;
  }

  &.red-24-bold {
    color: $primary_red_color;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &.red-24-consent {
    color: $primary_red_color;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.blue-20 {
    font-size: 20px;
    letter-spacing: 0.54px;
    color: $primary_blue_color;
    font-size: 20px;
    text-decoration: underline;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.red-24-bold-user {
    color: $primary_red_color;
    font-size: 24px;
    font-weight: bold;
    padding-left: 20px;
  }

}


