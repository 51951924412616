@import "./styles/fonts.scss";
@import "./styles/buttons.scss";
@import "./styles/variables.scss";
@import "./styles/ng-select-custom.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./styles/cookie.scss";
@import "./styles/iphone5.scss";
@import "./styles/sos.cookiebot.css";

html, body {
  height: 100%;
  background-color: $primary_bg_color !important;
  overflow-x: hidden;
  font-family: "Effra", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

.content {
  height: 100%;
  max-width: 350px;
  margin: 0 auto 10px;
}

.navbar {
  display: block;
  z-index: 1;
}

.pointer {
  cursor: pointer;
}
