@import "./variables.scss";

input {
  color: transparent;
}

.ng-select.ng-select-single .ng-select-container {
  height: 50px;
  width: 335px;
  background-color: $primary_bg_color;
  color: #999999;
  margin: auto;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-width: 8px 8px 1px;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-width: 0px 8px 8px;
}

.ng-dropdown-panel {
  width: 335px !important;
  margin: auto !important;
}

.ng-select-image-flag {
  height: 30px;
  width: 45px;
  margin-left: 5px;
  margin-right: 10px;
}

//iPhone 5
@media (max-width: 325px) {
  .ng-select.ng-select-single .ng-select-container {
    width: 290px;
  }

  .ng-dropdown-panel {
    width: 290px !important;
    margin: auto !important;
  }
}
