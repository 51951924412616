@import "variables";

.custom {
  max-width: 290px !important;
  margin: 5px auto 0; //margin-top, margin-right, margin-bottom, margin-left
  background-color:$primary_red_color !important;
  color: white;

  &.custom-margin {
    margin-bottom: 10px !important;
    margin-top: 0 !important;
  }
}




