#CybotCookiebotDialog {
  z-index: 9999999999 !important;
  padding: 40px !important;
  padding-bottom: 0 !important;
  border-radius: 10px !important;
  border-color: #F2F2F2 !important;     /*  added because of task - Centrer Cookie bot  */
  box-shadow: 2px 2px 14px 0 rgba(0, 0, 0, 0.2) !important;
}

#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentControls,
#CybotCookiebotDialogBodyContentTitle {
  padding: 0 !important;
  font-family: "effra", "sans-serif" !important;
}

#CybotCookiebotDialog {
  /*border: 0 !important;*/     /*  Removed because of task - Centrer Cookie bot  */
}

#CybotCookiebotDialogBodyContent {
  padding-left: 0 !important;
}

#CybotCookiebotDialogBodyContentText {
  margin: 0 !important;
  margin-bottom: 20px !important;
  padding: 20px !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

#CybotCookiebotDialogBody {
  padding: 20px !important;
}

#CybotCookiebotDialogPoweredbyLink {
  display: none !important;
}

#CybotCookiebotDialogBodyContentTitle {
  margin-bottom: 20px !important;
  font-size: 20px !important;
}

/* Buttons */
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  margin-left: 0 !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  margin: 10px 0 30px !important;
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
  width: auto !important;
  height: auto !important;
  padding: 11px 22px !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 1 !important;
  background: transparent !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background: #f50a37 !important;
  border: 1px solid #f50a37 !important;
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  color: #808080 !important;
  border: 2px solid #808080 !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  margin-right: 20px !important;
  margin-left: 0 !important;
}

#CybotCookiebotDialogBodyLevelButtons {
  margin-left: 0 !important;
}

.CybotCookiebotDialogBodyButton {
  margin-left: 0 !important;
}

#CybotCookiebotDialogBodyLevelDetailsButton {
  display: inherit !important;
  width: auto !important;
  border-bottom: 0 !important;
}

/* Selector */
#CybotCookiebotDialogBodyLevelButtonsTable {
  display: block !important;
  width: 574px !important;
  height: auto !important;
}

div#CybotCookiebotDialogBodyLevelButtonsSelectPane {
  display: flex !important;
  justify-content: space-between !important;
  padding: 0 !important;
  font-size: 14px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
}

a#CybotCookiebotDialogBodyLevelDetailsButton {
  width: auto !important;
  padding: 0 15px !important;
  font-size: 14px !important;
  line-height: 35px !important;
  background-position-y: center !important;
}

#CybotCookiebotDialogBodyLevelDetailsWrapper {
  display: flex !important;
  justify-content: center !important;
  height: auto !important;
  background: transparent !important;
  border: 0 !important;
}

#CybotCookiebotDialog div.CybotCookiebotDialogBodyLevelButtonWrapper {
  display: flex !important;
  flex: 1 1 auto !important;
  align-items: center !important;
  justify-content: center !important;
  width: auto !important;
  margin-right: 0 !important;
  padding: 15px 0 !important;
  text-align: center !important;
  border-right: 1px solid #ccc !important;
}

#CybotCookiebotDialog div.CybotCookiebotDialogBodyLevelButtonWrapper:last-child {
  border: 0 !important;
}

#CybotCookiebotDialogBodyLevelButtonsRow {
  display: block !important;
}

/* Tabs */
#CybotCookiebotDialogDetailBodyContentTabsOverview,
#CybotCookiebotDialogDetailBodyContentTabsAbout {
  background: transparent !important;
  border: none !important;
}

#CybotCookiebotDialogDetailBodyContent div#CybotCookiebotDialogDetailBodyContentTextAbout {
  padding: 10px !important;
}

#CybotCookiebotDialogDetailBodyContentTabs {
  margin-bottom: 5px !important;
}

.CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected,
.CybotCookiebotDialogDetailBodyContentCookieContainerTypes:hover {
  color: #f50a37 !important;
}

#CybotCookiebotDialogDetailBodyContentTextAbout {
  height: 170px !important;
  padding: 15px !important;
}

a.CybotCookiebotDialogDetailBodyContentTab {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

a.CybotCookiebotDialogDetailBodyContentTab:hover {
  color: #f50a37 !important;
}

a.CybotCookiebotDialogDetailBodyContentTab:first-child {
  padding-left: 0 !important;
}

#CybotCookiebotDialogDetailBodyContentTabsOverview {
  margin-right: 20px !important;
  padding-right: 0 !important;
}

#CybotCookiebotDialogDetailBodyContentTabsAbout {
  padding-left: 0 !important;
}

a.CybotCookiebotDialogDetailBodyContentTabsItemSelected {
  color: #f50a37 !important;
}

.CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
  padding: 15px !important;
  line-height: 17px !important;
}

a.CybotCookiebotDialogDetailBodyContentCookieContainerTypes:hover {
  color: #f50a37 !important;
}

.CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
  background: #fff !important;
}

#CybotCookiebotDialogDetailBodyContent a {
  padding: 9.4px !important;
}

input[type="checkbox"].CybotCookiebotDialogBodyLevelButton {
  display: none;

  /* Checked state */

  /* Disabled */
}

input[type="checkbox"].CybotCookiebotDialogBodyLevelButton + label {
  padding: 0 !important;
  line-height: 1 !important;
  background: none !important;
}

input[type="checkbox"].CybotCookiebotDialogBodyLevelButton + label::before {
  position: relative !important;
  display: block !important;
  width: 65px !important;
  height: 40px !important;
  margin-bottom: 15px !important;
  background: #aaa !important;
  border-radius: 100px !important;
  cursor: pointer !important;
  transition: 0.3s ease !important;
  content: "" !important;
}

input[type="checkbox"].CybotCookiebotDialogBodyLevelButton + label::after {
  position: absolute !important;
  top: 5px !important;
  left: 5px !important;
  display: block !important;
  box-sizing: border-box !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff !important;
  border-radius: 100px !important;
  transition: transform 0.3s !important;
  content: "" !important;
}

input[type="checkbox"].CybotCookiebotDialogBodyLevelButton:checked + label {
  background: none !important;
}

input[type="checkbox"].CybotCookiebotDialogBodyLevelButton:checked + label::before {
  background: #f50a37 !important;
}

input[type="checkbox"].CybotCookiebotDialogBodyLevelButton:checked + label::after {
  transform: translateX(25px) !important;
}

input[type="checkbox"].CybotCookiebotDialogBodyLevelButton[disabled] + label::before {
  cursor: default !important;
  opacity: 0.3 !important;
}
